import React from 'react'
import Navbar2 from '../Components/Navbars/Navbar2'

export default function About() {
  return (
    <div className='body'>
      <Navbar2 page = 'About'/>
      <h1>ABOUT</h1>
    </div>
  )
}
