import React from 'react'
import Navbar2 from '../Components/Navbars/Navbar2'
import './Home.css'
import Header from '../Components/Navbars/Header'

export default function Home() {
  return (
    <div className='body'>
      <Navbar2 page = 'Home'/>
      <h1>1. HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
      <h1>HOME</h1>
    </div>
  )
}
