import React from 'react'
import Navbar2 from '../Components/Navbars/Navbar2'

export default function Signup() {
  return (
    <div>
      <Navbar2/>
      <h1>Sign Up</h1>
    </div>
  )
}
