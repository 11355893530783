import React from 'react'
import Navbar2 from '../Components/Navbars/Navbar2'

export default function Signin() {
  return (
    <div>
      <Navbar2/>
      <h1>SIGN IN</h1>
    </div>
  )
}
